import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { GET_ALL_KATEGORI_SAMPAH } from '../../api/kategori_sampah';
import useScriptRef from '../../hooks/useScriptRef';
import SelectInput from '../../components/SelectInput';
import {GET_MITRA_ALL_BY_SU_YES} from '../../api/mitra';

export default function DialogComponent(props) {
  const { open, onClose, item, onAdd, onUpdate, processing } = props;
  const editMode = Boolean(item && item.gaCode);
  const scriptedRef = useScriptRef();
  const [kategoriOption, setKategoriOption] = useState([]);
  const [mitraOptions, setMitraOptions] = useState([]);

  async function getMitra() {
    try {
      const res = await GET_MITRA_ALL_BY_SU_YES();
      console.log(res)
      const list =
      res &&
      [
        ...res.data?.data?.map((p) => ({ value: p.mitraCode, label: p.nama })),
      ];
      setMitraOptions(list);
      
    } catch (e) {
      setMitraOptions([]);
      console.error("Error fetching group anggota:", e);
    } 
  }

// const GET_MITRA_ALL_BY_SU_YES = async () => {
//   const headers = {
//     Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
//   };
//   try {
//     const response = await axios.get(`su/allMitra/yes`, { headers });
//     if (!response.ok) {
//       throw new Error('Failed to fetch data');
//     }
//     const result = await response.json();
//     const mitraOptions = result.data.map((mitra) => ({
//       value: mitra.mitraCode,
//       label: mitra.nama,
//     }));
//     setMitraOptions(mitraOptions);
//   } catch (error) {
//     console.error('Error fetching mitra data:', error);
//     throw error;
//   }
// };  

const handleSubmit = (values, { setErrors, setStatus, setSubmitting }) => {
    try {
    if (editMode) {
      onUpdate({ ...values }, item.gaCode,item.mitraCode, setErrors);
    } else {
      onAdd({ ...values, mitra: values.mitra }, setErrors);
    }
    } catch (err) {
      if (scriptedRef.current) {
        setStatus({ success: false });
        setErrors({ submit: err.message });
        setSubmitting(false);
      }
    }
  };

  // async function getKat() {
  //   GET_ALL_KATEGORI_SAMPAH()
  //     .then((res) => {
  //       const list =
  //         res &&
  //         res.data?.data?.map((p) => {
  //           return { value: p.ksCode, label: p.kategori };
  //         });
  //       setKategoriOption(list);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }

  const formik = useFormik({
    initialValues: {
      gaDescription: item ? item.gaDescription : '',
      mitra: item ? item.mitraNm : '',
      
    },
    validationSchema: Yup.object({
      gaDescription: Yup.string().required('Harus Disisi'),
    }),
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    getMitra();
  }, []);

  return (
    <div>
      <Dialog fullWidth maxWidth="sm" open={open}>
        <DialogTitle> {editMode ? 'Edit' : 'Tambah'} Group Anggota</DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            {editMode ? (
              // Render TextField for editing mode
              <TextField
                margin="dense"
                name="mitra"
                id="mitra"
                label="Mitra"
                type="text"
                disabled={processing}
                value={formik.values.mitra}
                onChange={formik.handleChange}
                fullWidth
                InputProps={{ readOnly: true }} 
                error={formik.touched.gaDescription && Boolean(formik.errors.mitra)}
                variant="standard"
                helperText={formik.touched.gaDescription && formik.errors.mitra}
              />
            ) : (
              // Render SelectInput for add mode
              <SelectInput
                // Add props specific to SelectInput component
                // Example: options={kategoriOption}
                option={mitraOptions}
                label="Mitra"
                name="mitra"
                value={formik.values.mitra}
                onChange={formik.handleChange}
                fullWidth
                disabled={processing}
              />
            )}

            <TextField
              margin="dense"
              name="gaDescription"
              id="gaDescription"
              label="Group Anggota"
              type="text"
              disabled={processing}
              value={formik.values.gaDescription}
              onChange={formik.handleChange}
              fullWidth
              error={formik.touched.gaDescription && Boolean(formik.errors.gaDescription)}
              variant="standard"
              helperText={formik.touched.gaDescription && formik.errors.gaDescription}
            />
          </DialogContent>
          <DialogActions>
            <Button disabled={processing} onClick={onClose}>
              Cancel
            </Button>
            <Button variant="contained" type="submit" disabled={processing}>
              {editMode ? 'Edit' : 'Tambah'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
DialogComponent.propTypes = {
  open: PropTypes.any,
  onClose: PropTypes.any,
  item: PropTypes.any,
  onAdd: PropTypes.any,
  onUpdate: PropTypes.any,
  processing: PropTypes.any,
};
