import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { GET_ALL_KATEGORI_SAMPAH } from '../../api/kategori_sampah';
import useScriptRef from '../../hooks/useScriptRef';
import SelectInput from '../../components/SelectInput';
import {GET_ALL_ADMIN,GET_ALL_FASILITATOR} from '../../api/fasilitator_mapping';

export default function DialogComponent(props) {
  const { open, onClose, item, onAdd, onUpdate, processing } = props;
  const editMode = Boolean(item && item.jsCode);
  const scriptedRef = useScriptRef();
  const [kategoriOption, setKategoriOption] = useState([]);
  const [admin, setAdmin] = useState([]);
  const [fasilitator, setFasilitator] = useState([]);
  
  async function getAdmin() {
    try {
      const res = await GET_ALL_ADMIN();
      console.log(res)
      const list =
      res &&
      [
        ...res.data?.data?.map((p) => ({ value: p.admin, label: p.admin_nama })),
      ];
      setAdmin(list);
      
    } catch (e) {
      setAdmin([]);
      console.error("Error fetching admin:", e);
    } 
  }

  async function getFasilitator() {
    try {
      const res = await GET_ALL_FASILITATOR();
      console.log(res)
      const list =
      res &&
      [
        ...res.data?.data?.map((p) => ({ value: p.fasilitatorCode, label: p.nama })),
      ];
      setFasilitator(list);
      
    } catch (e) {
      setFasilitator([]);
      console.error("Error fetching fasilitator:", e);
    } 
  }
   
  const handleSubmit = (values, { setErrors, setStatus, setSubmitting }) => {
    try {
      if (item && item.fCode) {
        onUpdate({ ...values }, item.adminCode, item.fCode, setErrors);
      } else {
        onAdd({ ...values, admin: values.admin, fasilitator: values.fasilitator }, setErrors);
      }
    } catch (err) {
      if (scriptedRef.current) {
        setStatus({ success: false });
        setErrors({ submit: err.message });
        setSubmitting(false);
      }
    }
  };

  // async function getKat() {
  //   GET_ALL_KATEGORI_SAMPAH()
  //     .then((res) => {
  //       const list =
  //         res &&
  //         res.data?.data?.map((p) => {
  //           return { value: p.ksCode, label: p.kategori };
  //         });
  //       setKategoriOption(list);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }

  const formik = useFormik({
    initialValues: {
      admin: item ? item.adminCode : '',
      fasilitator: item ? item.fCode : '',
    },
    validationSchema: Yup.object({
      admin: Yup.string().required('Harus Disisi'),
      fasilitator: Yup.string().required('Harus Disisi'),
    }),
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    getAdmin();
    getFasilitator();
  }, []);

  return (
    <div>
      <Dialog fullWidth maxWidth="sm" open={open}>
        <DialogTitle> {editMode ? 'Edit' : 'Tambah'} Fasilitator Mapping </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
          <SelectInput
                // Add props specific to SelectInput component
                // Example: options={kategoriOption}
                option={admin}
                label="Admin"
                name="admin"
                value={formik.values.admin}
                onChange={formik.handleChange}
                fullWidth
                disabled={processing}
              />
            <SelectInput
                // Add props specific to SelectInput component
                // Example: options={kategoriOption}
                option={fasilitator}
                label="Fasilitator"
                name="fasilitator"
                value={formik.values.fasilitator}
                onChange={formik.handleChange}
                fullWidth
                disabled={processing}
              />
         
            {/* <TextField
              margin="dense"
              name="jmDescription"
              id="jmDescription"
              label="Jenis Mitra"
              type="text"
              disabled={processing}
              value={formik.values.jmDescription}
              onChange={formik.handleChange}
              fullWidth
              error={formik.touched.jmDescription && Boolean(formik.errors.jmDescription)}
              variant="standard"
              helperText={formik.touched.jmDescription && formik.errors.jmDescription}
            /> */}
          </DialogContent>
          <DialogActions>
            <Button disabled={processing} onClick={onClose}>
              Cancel
            </Button>
            <Button variant="contained" type="submit" disabled={processing}>
              {editMode ? 'Edit' : 'Tambah'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
DialogComponent.propTypes = {
  open: PropTypes.any,
  onClose: PropTypes.any,
  item: PropTypes.any,
  onAdd: PropTypes.any,
  onUpdate: PropTypes.any,
  processing: PropTypes.any,
};
