import { Button, Card, Container, Stack, TableCell, TableRow } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
// import { useMee } from 'contexts/MeContext';
import * as React from 'react';
import { useMutation, useQuery } from 'react-query';
import SelectInput from '../../components/SelectInput';
import { InputLabel, MenuItem, Select } from '@mui/material';
import { useEffect, useState } from 'react';



  import {
    GET_ALL_GROUP_ANGGOTA,
    UPDATE_GROUP_ANGGOTA,
    ADD_GROUP_ANGGOTA,
    DELETE_GROUP_ANGGOTA,
    GET_ANGGOTA_COUNT,
  } from '../../api/group_anggota';

import DialogConfirm from '../../components/DialogConfirm';
import Page from '../../components/Page';
import useTable from '../../hooks/useTable/index';
import Action from './Action';
import DialogComponent from './DialogComponent';
import { convertSpeed } from 'geolib';
import {GET_MITRA_ALL_BY_SU_YES} from '../../api/mitra';

const headCells = [
    {
      id: 'nama',
      numeric: false,
      disablePadding: true,
      label: 'Mitra',
    },
    {
      id: 'gaDescription',
      numeric: false,
      disablePadding: true,
      label: 'Group Anggota',
    },
    
    // {
    //   id: 'kategori',
    //   numeric: false,
    //   disablePadding: true,
    //   label: 'Kategori Sampah',
    // },
  ];
  
  export default function Index() {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [alertText, setAlertText] = React.useState('');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [itemSelected, setItemSelected] = React.useState(null);
    const [mitraOptions, setMitraOptions] = useState([]);
    const [selectedMitra, setSelectedMitra] = useState('all'); // Initialize with the default value
    const [search, setSearch] = useState('');
    //   const { checkPermision } = useMee();
    // const { data, isLoading, refetch } = useQuery('GET_ALL_JENIS_MITRA', GET_ALL_JENIS_MITRA);
    const { data, isLoading, refetch } = useQuery('GET_ALL_GROUP_ANGGOTA', GET_ALL_GROUP_ANGGOTA);
    const { enqueueSnackbar } = useSnackbar();
   
  
    const rows = data && data?.data?.data;

    // const handleMitraChange = (event) => {
    //   const value = event.target.value;
    //   setSelectedMitra(value);
    //   const filteredRows = value === 'all' ? rows : rows.filter(row => row.mitraCode === value);
    //   setList(filteredRows);
    // };

   
    // const filteredRows = selectedMitra === 'all' ? rows : rows.filter(row => row.mitraCode === selectedMitra);



    async function getMitra() {
      try {
        const res = await GET_MITRA_ALL_BY_SU_YES();
        const list = res.data.data.map((p) => ({ value: p.mitraCode, label: p.nama }));
        setMitraOptions([{ value: 'all', label: 'All' }, ...list]);
      } catch (error) {
        console.error("Error fetching mitra:", error);
      }
    }

    // const filteredRows = rows.filter(row => {
    //   const mitraMatch = selectedMitra === 'all' || row.mitraCode === selectedMitra;
    //   const searchMatch = row.nama.toLowerCase().includes(search.toLowerCase()) ||
    //                       row.gaDescription.toLowerCase().includes(search.toLowerCase());
    //   return mitraMatch && searchMatch;
    // });

    const filteredRows = rows ? rows.filter(row => {
      const mitraMatch = selectedMitra === 'all' || row.mitraCode === selectedMitra;
      const searchMatch = (row.nama ?? '').toLowerCase().includes(search.toLowerCase()) ||
                          (row.gaDescription ?? '').toLowerCase().includes(search.toLowerCase());
      return mitraMatch && searchMatch;
    }) : [];
    
    

    const handleMitraChange = (event) => {
      setSelectedMitra(event.target.value);
    };
  
    const { TableComponent,list } = useTable({
      header: headCells,
      rows: filteredRows || [],
      loading: isLoading,
    });
    // HANDLE ACTION
    const handleActionOpen = (event, item) => {
      console.log("Selected Item:", item);
      setItemSelected(item);
      setAnchorEl(event.currentTarget);
    };
    const handleActionClose = () => {
      setItemSelected(null);
      setAnchorEl(null);
    };
    // HANDLE MODAL
    const handleEdit = () => {
      setDialogOpen(true);
    };
  
    // HANDLE ALERT
    const handleAlertOpen = (text) => {
      setAlertText(text);
      setAlertOpen(true);
    };
    const handleAlertClose = () => {
      setAlertText('');
      setAlertOpen(false);
    };
  
    
    // HANDLE ACTION
    const onAdd = async (data, callbackSetError) => {
      setLoading(true);
      console.log(data)
      const response = await ADD_GROUP_ANGGOTA(data);
      if (response.status === 400) {
        callbackSetError(response.data.error.form);
      }
      if (response.status === 422) {
        const asdf = response.data.errors;
        const keys = asdf && Object.keys(asdf);
        keys.forEach((key) => {
          enqueueSnackbar(asdf[key].msg, { variant: 'warning' });
        });
      }
      if (response.status === 200) {
        await refetch();
        await enqueueSnackbar(response.data.message, { variant: 'success' });
        setDialogOpen(false);
        handleActionClose();
      }
      await setLoading(false);
    };
    const onUpdate = async (data, id,mitraCode, callbackSetError) => {
      setLoading(true);
      const response = await UPDATE_GROUP_ANGGOTA(data, id,mitraCode);
      if (response.data.status === 400) {
        callbackSetError(response.data.error.form);
      }
      if (response.data.status === 200) {
        await refetch();
        await enqueueSnackbar(response.data.message, { variant: 'success' });
        handleActionClose();
        setDialogOpen(false);
        setItemSelected(null);
      }
      await setLoading(false);
    };

    const deleteMutation = useMutation((params) => DELETE_GROUP_ANGGOTA(params.id,params.mitraCode), {
      onSuccess: async (res) => {
        const variant = res.status === 200 ? 'success' : 'warning';
        await enqueueSnackbar(res.data.message, { variant });
        await refetch();
        handleActionClose();
        handleAlertClose();
        setItemSelected(null);
      },
      onError: async (e) => {
        await enqueueSnackbar(e.message, 'error');
      },
    });

    const checkAnggotaMutation = useMutation(
      (params) => GET_ANGGOTA_COUNT(params.id,params.mitraCode),
      {
        onSuccess: async (data) => {
          const { count, message } = data.data.data;
          console.log(data.data.data.count)
          const variant = count > 0 ? 'warning' : 'success';
          if (count > 0) {
            enqueueSnackbar("Data Anggota Masih ada", { variant: 'warning' });
            await refetch();
            handleActionClose();
            handleAlertClose();
            setItemSelected(null);
          } else {
            deleteMutation.mutate({ id: itemSelected.gaCode, mitraCode: itemSelected.mitraCode });
          }
        },
        onError: async (error) => {
          console.error("Error fetching anggota count:", error);
          await enqueueSnackbar(error.message, 'error');
        },
      }
    );
    
    const handleConfirm = async () => {
      try {
        console.log(itemSelected.gaCode)
        console.log(itemSelected.mitraCode)
        checkAnggotaMutation.mutate({
          id: itemSelected.gaCode,
          mitraCode: itemSelected.mitraCode,
        }); 
      } catch (error) {
        console.error("Error performing mutation:", error);
      }
    };
    
  

    // const handleConfirm = async () => {
    //   try {
    //     checkAnggota.mutate({id: itemSelected.gaCode, mitraCode: itemSelected.mitraCode});
    //     const count = checkAnggota.data.count;
    //     if(count > 0){
    //       console.log("Data Anggota Masih ada")
    //       await refetch();
    //       handleActionClose();
    //       handleAlertClose();
    //       setItemSelected(null);
    //     }else{
    //         deleteMutation.mutate({ id: itemSelected.gaCode, mitraCode: itemSelected.mitraCode });
    //     }
    //   } catch (error) {
    //     console.error("Error fetching mitra:", error);
    //   }
      
    // };
    
  
    useEffect(() => {
      getMitra();
    }, []);


    

    const actionOpen = Boolean(anchorEl);
    const processing = loading || isLoading || deleteMutation.isLoading;
    return (
      <Page title="Group Anggota">
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Group Anggota
            </Typography>
            <Button onClick={() => setDialogOpen(true)} variant="contained">
              Tambah
            </Button>
            {/* )} */}
          </Stack>

          <Card>
      <InputLabel sx={{ marginTop: 2,marginLeft: 3 }} >
        {"Mitra"}
      </InputLabel>
      <Select
            fullWidth
            labelId={"mitra"}
            placeholder={"Mitra"}
            variant="standard"
            sx={{ width: 400, marginLeft: 3 }}
            id={`${"mitra".split(' ').join('_')}_id_standard`}
            label={"Mitra"}
            value={selectedMitra}
            onChange={handleMitraChange}
          >
            {mitraOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          {/* <input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search"
          /> */}

            {list  &&
              TableComponent(
                list.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow onClick={(event) => handleActionOpen(event, row)} hover tabIndex={-1} key={index}>
                      <TableCell>{row.no}</TableCell>
                      <TableCell id={labelId} scope="row">
                        { row.mitraNm}
                      </TableCell>
                      <TableCell id={labelId} scope="row">
                        {row.gaDescription}
                      </TableCell>
                      {/* <TableCell id={labelId} scope="row">
                        {row.kategori}
                      </TableCell> */}
                    </TableRow>
                  );
                })
              )}
          </Card>
        </Container>
        {dialogOpen && (
          <DialogComponent
            processing={processing}
            onAdd={onAdd}
            onUpdate={onUpdate}
            onClose={() => setDialogOpen(false)}
            item={itemSelected}
            open={dialogOpen}
          />
        )}
        {actionOpen && (
          <Action
            actionOpen={actionOpen}
            handleEdit={handleEdit}
            handelDelete={() => handleAlertOpen('Apakah yakin mau delete')}
            anchorEl={anchorEl}
            actionClose={handleActionClose}
          />
        )}
        {alertOpen && (
          <DialogConfirm
            processing={processing}
            alertClose={handleAlertClose}
            alertOpen={alertOpen}
            handleConfirm={handleConfirm}
            text={alertText}
          />
        )}
      </Page>
    );
  }

