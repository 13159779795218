import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { ListItemIcon, Menu, MenuItem } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import useAuth from 'src/hooks/useAuth';

export default function Action(props) {
  const { actionOpen, actionClose, anchorEl, handelDelete, handleEdit } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { auth } = useAuth();
  const handleClickTambah = async () => {
    if (auth.role == 'Mitra') {
      
      try {
        // Perform the action
        // For now, let's assume a successful response
        const response = { data: { message: 'You dont have acces' } };
        await enqueueSnackbar(response.data.message, { variant: 'error' });
      } catch (error) {
        // Handle error
        console.error('Error:', error);
      }
    
    } else {
      handleEdit();
    }
      
  };

  const handleClickDelete = async () => {
    if (auth.role == 'Mitra') {
      
      try {
        // Perform the action
        // For now, let's assume a successful response
        const response = { data: { message: 'You dont have acces' } };
        await enqueueSnackbar(response.data.message, { variant: 'error' });
      } catch (error) {
        // Handle error
        console.error('Error:', error);
      }
    
    } else {
      handelDelete();
    }

    
      
  };
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={actionOpen}
      onClose={actionClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuItem onClick={handleClickTambah}>
        <ListItemIcon>
          <EditIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Edit</ListItemText>
      </MenuItem>
      <MenuItem onClick={handleClickDelete}>
        <ListItemIcon>
          <DeleteIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Delete</ListItemText>
      </MenuItem>
    </Menu>
  );
}

Action.propTypes = {
  actionOpen: PropTypes.any,
  actionClose: PropTypes.any,
  handelDelete: PropTypes.any,
  handleEdit: PropTypes.any,
  anchorEl: PropTypes.any,
};
