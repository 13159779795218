import qs from 'qs';
import axios, { catchCallBack } from '../index';

const GET_ALL_FASILITATOR_MAP = async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  };
  try {
    const response = await axios.get('master/fasilitator-map/all', { headers });
    return response;
  } catch (error) {
    return catchCallBack(error);
  }
};

const GET_ALL_ADMIN = async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  };
  try {
    const response = await axios.get('user/admin/all', { headers });
    return response;
  } catch (error) {
    return catchCallBack(error);
  }
};

const GET_ALL_FASILITATOR = async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  };
  try {
    const response = await axios.get('fasilitator-admin/all', { headers });
    return response;
  } catch (error) {
    return catchCallBack(error);
  }
};

const GET_ONE_JENIS_MITRA = async (id) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  };
  try {
    const response = await axios.get(`master/jenisMitra/one/${id}`, { headers });
    return response;
  } catch (error) {
    return catchCallBack(error);
  }
};

const ADD_FASILITATOR_MAPPING = async ({ admin,fasilitator }) => {
  const data = qs.stringify({
    admin,
    fasilitator
  });
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  };
  try {
    const response = await axios.post(`fasilitator-admin/add`, data, { headers });
    return response;
  } catch (error) {
    return catchCallBack(error);
  }
};




// const UPDATE_FASILITATOR_MAP = async ({ fCode,adminCode }, id) => {
//   const data = qs.stringify({
//     fCode,
//     adminCode
//   });
//   const headers = {
//     Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
//   };
//   try {
//     const response = await axios.put(`master/jenisMitra/edit/${id}`, data, { headers });
//     return response;
//   } catch (error) {
//     return catchCallBack(error);
//   }
// };
const DELETE_FASILITATOR_MAP = async (id1, id2) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  };
  try {
    const response = await axios.delete(`master/fasilitator-admin/delete/${id1}/${id2}`, { headers });
    return response;
  } catch (error) {
    return catchCallBack(error);
  }
};
export { ADD_FASILITATOR_MAPPING,GET_ALL_ADMIN ,GET_ALL_FASILITATOR, GET_ALL_FASILITATOR_MAP, DELETE_FASILITATOR_MAP, GET_ONE_JENIS_MITRA };
