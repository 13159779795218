import { Button, Card, Container, Stack, TableCell, TableRow } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
// import { useMee } from 'contexts/MeContext';
import * as React from 'react';
import { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  GET_ALL_FASILITATOR_MAP,
  ADD_FASILITATOR_MAPPING,
  // UPDATE_FASILITATOR_MAP
  DELETE_FASILITATOR_MAP,
  GET_ALL_ADMIN
  } from '../../api/fasilitator_mapping';
  import { InputLabel, MenuItem, Select } from '@mui/material';

import DialogConfirm from '../../components/DialogConfirm';
import Page from '../../components/Page';
import useTable from '../../hooks/useTable/index';
import Action from './Action';
import DialogComponent from './DialogComponent';
import { ADD_FASILITATOR, DELETE_FASILITATOR, UPDATE_FASILITATOR } from 'src/api/fasilitator';

const headCells = [
    {
      id: 'fCode',
      numeric: false,
      disablePadding: true,
      label: 'Fasilitator',
    },
    
    {
      id: 'admin',
      numeric: false,
      disablePadding: true,
      label: 'Admin',
    },
  ];
  
  export default function Index() {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [alertText, setAlertText] = React.useState('');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [itemSelected, setItemSelected] = React.useState(null);
    //   const { checkPermision } = useMee();
    const [selectedAdmin, setSelectedAdmin] = React.useState('all'); // Initialize with the default value
    const [adminOptions, setAdmin] = React.useState([]);
    const { data, isLoading, refetch } = useQuery('GET_ALL_FASILITATOR_MAP', GET_ALL_FASILITATOR_MAP);
    const { enqueueSnackbar } = useSnackbar();
  
    const rows = data && data?.data?.data;

    const filteredRows = rows ? rows.filter(row => {
      const mitraMatch = selectedAdmin === 'all' || row.adminCode === selectedAdmin;
      const searchMatch = (row.fCode ?? '');
      return mitraMatch && searchMatch;
    }) : [];
  
    const { TableComponent, list } = useTable({
      header: headCells,
      rows: filteredRows || [],
      loading: isLoading,
    });
    // HANDLE ACTION
    const handleActionOpen = (event, item) => {
      console.log(item)
      setItemSelected(item);
      setAnchorEl(event.currentTarget);
    };
    const handleActionClose = () => {
      setItemSelected(null);
      setAnchorEl(null);
    };
    // HANDLE MODAL
    const handleEdit = () => {
      setDialogOpen(true);
    };
  
    // HANDLE ALERT
    const handleAlertOpen = (text) => {
      setAlertText(text);
      setAlertOpen(true);
    };
    const handleAlertClose = () => {
      setAlertText('');
      setAlertOpen(false);
    };
  
    const deleteMutation = useMutation((params) => DELETE_FASILITATOR_MAP(params.id1,params.id2), {
      onSuccess: async (res) => {
        const variant = res.status === 200 ? 'success' : 'warning';
        await enqueueSnackbar(res.data.message, { variant });
        await refetch();
        handleActionClose();
        handleAlertClose();
        setItemSelected(null);
      },
      onError: async (e) => {
        await enqueueSnackbar(e.message, 'error');
      },
    });
    // HANDLE ACTION
    const onAdd = async (data, callbackSetError) => {
      setLoading(true);
      const response = await ADD_FASILITATOR_MAPPING(data);
      if (response.status === 400) {
        callbackSetError(response.data.error.form);
      }
      if (response.status === 422) {
        const asdf = response.data.errors;
        const keys = asdf && Object.keys(asdf);
        keys.forEach((key) => {
          enqueueSnackbar(asdf[key].msg, { variant: 'warning' });
        });
      }
      if (response.status === 200) {
        await refetch();
        await enqueueSnackbar(response.data.message, { variant: 'success' });
        setDialogOpen(false);
        handleActionClose();
      }
      await setLoading(false);
    };
    const onUpdate = async (data, id, callbackSetError) => {
      setLoading(true);
      const response = await UPDATE_FASILITATOR_MAP(data, id);
      if (response.data.status === 400) {
        callbackSetError(response.data.error.form);
      }
      if (response.data.status === 200) {
        await refetch();
        await enqueueSnackbar(response.data.message, { variant: 'success' });
        handleActionClose();
        setDialogOpen(false);
        setItemSelected(null);
      }
      await setLoading(false);
    };
    const onDelete = async () => {
      console.log("INI")
      console.log(itemSelected)
      deleteMutation.mutate({ id1: itemSelected.fCode,id2: itemSelected.adminCode });
    };
    const handleConfirm = async () => {
      await onDelete();
    };
    
    const handleMitraChange = (event) => {
      setSelectedAdmin(event.target.value);
    };

    async function getAdmin() {
      try {
        const res = await GET_ALL_ADMIN();
        const list = res.data.data.map((p) => ({ value: p.admin, label: p.admin_nama }));
        setAdmin([{ value: 'all', label: 'All' }, ...list]);
      } catch (error) {
        console.error("Error fetching mitra:", error);
      }
    }

    useEffect(() => {
      getAdmin();
    }, []);
  
    

    const actionOpen = Boolean(anchorEl);
    const processing = loading || isLoading || deleteMutation.isLoading;
    return (
      <Page title="Fasilitator Mapping">
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
            Fasilitator Mapping
            </Typography>
            <Button onClick={() => setDialogOpen(true)} variant="contained">
              Tambah
            </Button>
            {/* )} */}
          </Stack>
  
          <Card>

          <InputLabel sx={{ marginTop: 2,marginLeft: 3 }} >
        {"Admin"}
      </InputLabel>
      <Select
            fullWidth
            labelId={"admin"}
            placeholder={"Admin"}
            variant="standard"
            sx={{ width: 400, marginLeft: 3 }}
            id={`${"admin".split(' ').join('_')}_id_standard`}
            label={"Admin"}
            value={selectedAdmin}
            onChange={handleMitraChange}
          >
            {adminOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          {/* <input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search"
          /> */}


            {list &&
              TableComponent(
                list.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow onClick={(event) => handleActionOpen(event, row)} hover tabIndex={-1} key={index}>
                      <TableCell>{row.no}</TableCell>
                      <TableCell id={labelId} scope="row">
                        {row.mitraNm}
                      </TableCell>
                      <TableCell id={labelId} scope="row">
                        {row.adminCode}
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
          </Card>
        </Container>
        {dialogOpen && (
          <DialogComponent
            processing={processing}
            onAdd={onAdd}
            onUpdate={onUpdate}
            onClose={() => setDialogOpen(false)}
            item={itemSelected}
            open={dialogOpen}
          />
        )}
        {actionOpen && (
          <Action
            actionOpen={actionOpen}
            handleEdit={handleEdit}
            handelDelete={() => handleAlertOpen('Apakah yakin mau delete')}
            anchorEl={anchorEl}
            actionClose={handleActionClose}
          />
        )}
        {alertOpen && (
          <DialogConfirm
            processing={processing}
            alertClose={handleAlertClose}
            alertOpen={alertOpen}
            handleConfirm={handleConfirm}
            text={alertText}
          />
        )}
      </Page>
    );
  }

